import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    if (this.authService.isAuthenticated instanceof Observable) {
      return this.authService.isAuthenticated.pipe(
        take(1),
        map((isAuthenticated) => {
          if (!isAuthenticated) {
            this.router.navigate(['/login']);
            return false;
          }
          return true;
        }),
        catchError(() => {
          this.router.navigate(['/login']);
          return of(false);
        })
      );
    } else {
      if (!this.authService.isAuthenticated) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }
  }
}
