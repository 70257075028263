// src/app/components/photo-prompts/photo-prompts.component.ts
import { Component, OnInit } from "@angular/core";
import {
  PhotoPrompt,
  PhotoPromptCollectionResponse,
  PhotoPromptCollectionUpdateRequest,
  SamplerOptions,
} from "src/app/services/api.service";
import { PhotoPromptsService } from "src/app/services/photo-prompts.service";
import { FormatDate } from "src/app/helpers/format-date.service copy";
import { ClipboardService } from "src/app/helpers/clipboard.service";
import { of, pipe } from "rxjs";
import { take } from "rxjs/operators";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { FileService } from "src/app/services/file.service"; // Импорт нового сервиса

@Component({
  selector: "app-photo-prompts",
  templateUrl: "./photo-prompts.component.html",
  styleUrls: ["./photo-prompts.component.scss"],
})
export class PhotoPromptsComponent implements OnInit {
  public photoPrompts: PhotoPrompt[] = [];
  public isLoading = true; // Flag to show loading indicator
  addPhotoPromptMode: boolean = false; // Для отслеживания состояния добавления нового фото промпта

  // This will hold the state of editing for each prompt
  public editCache: { [key: number]: { edit: boolean; data: PhotoPrompt } } =
    {};
  isNewCollectionActive = false;
  filterText: string;
  public photoPromptCollections: PhotoPromptCollectionResponse[] = [];
  newCollectionReset: PhotoPromptCollectionUpdateRequest = {
    id: null, // ID будет установлено при создании коллекции
    name: "", // Название новой коллекции
    priority: 0,
    isActive: false,
    isNsfw: false,
    featured: false,
    featuredStartDate: null,
    featuredEndDate: null,
    image: null // Новое поле
  };
  newCollection = this.newCollectionReset;
  isAllExpanded = false;
  showOnlyActive = false;
  samplerOptions = SamplerOptions;
  tempCollectionImages: { [key: number]: string } = {}; // Временное хранилище изображений коллекций

  constructor(
    private photoPromptsService: PhotoPromptsService,
    public formatDate: FormatDate,
    public clipboardService: ClipboardService,
    private snackBar: MatSnackBar,
    private fileService: FileService // Используем новый сервис
  ) { }

  ngOnInit(): void {
    // this.loadPhotoPrompts();
    this.loadPhotoPromptCollections();
  }

  loadPhotoPromptCollections(): void {
    this.photoPromptsService.getPhotoPromptCollections().pipe(take(1)).subscribe({
      next: (collections) => {
        this.photoPromptCollections = collections;
        collections.forEach((collection) => {
          collection.photoPrompts.forEach((prompt) => {
            this.editCache[prompt.id] = { edit: false, data: { ...prompt } };
          });
        });
        this.isLoading = false;
      },
      error: (error) => {
        console.error("Error fetching photo prompt collections:", error);
        this.isLoading = false;
      }
    });
  }

  updateCollection(collection: PhotoPromptCollectionResponse): void {
    // Здесь логика отправки обновленных данных на сервер
    const updateRequest: PhotoPromptCollectionUpdateRequest = {
      id: collection.id,
      name: collection.name,
      priority: collection.priority,
      isActive: collection.isActive,
      isNsfw: collection.isNsfw,
      featured: collection.featured,
      featuredStartDate: collection.featuredStartDate,
      featuredEndDate: collection.featuredEndDate,
      image: this.tempCollectionImages[collection.id] || collection.imageUrl // Используем временное изображение, если оно есть
    };
    this.photoPromptsService
      .updatePhotoPromptCollection(updateRequest)
      .subscribe(
        (response) => {
          delete this.tempCollectionImages[collection.id]; // Удаляем временное изображение после сохранения
          this.loadPhotoPromptCollections();
        },
        (error) => {
          console.error("Error updating collection:", error);
        }
      );
  }

  tempId: number;
  addNewPrompt(collectionId: number) {
    this.addPhotoPromptMode = true;
    this.tempId = NaN;
    const newPrompt: PhotoPrompt = {
      id: this.tempId,
      image: "",
      name: "",
      category: "",
      gender: "female",
      prompt: "",
      negativePrompt: "",
      aDetailerPromptTemplate: "{{avatarName}} face <lora:{{avatarName}}:1>",
      aDetailerNegativePrompt: '',
      modelWeight: 1,
      priority: 1,
      photoPromptCollectionId: collectionId,
      adDenoisingStrength: null,
      aDetailerEnabled: true,
      aDetailerMaskBlur: 0,
      filterType: 'none',
      cfgScale: undefined,
      height: undefined,
      width: undefined,
      samplerName: undefined,
      steps: undefined
    };

    // Найти коллекцию по ID и добавить новый промпт в начало списка промптов
    const collectionIndex = this.photoPromptCollections.findIndex(
      (c) => c.id === collectionId
    );
    if (collectionIndex > -1) {
      this.photoPromptCollections[collectionIndex].photoPrompts.unshift(
        newPrompt
      );

      // Инициализируем режим редактирования для нового промпта
      this.editCache[newPrompt.id] = { edit: true, data: newPrompt };
    }
  }

  onFileSelected(event, promptId) {
    const file = event.target.files[0];
    if (file) {
      this.fileService.fileToBase64(file).subscribe({
        next: (base64Image: string) => {
          if (promptId === 'newCollection') {
            this.newCollection.image = base64Image;
          } else {
            const editingPrompt = this.editCache[promptId || this.tempId];
            if (editingPrompt) {
              editingPrompt.data.image = base64Image;
            }
          }
        },
        error: (error) => {
          console.error("Error converting file to base64:", error);
        }
      });
    }
  }

  onCollectionFileSelected(event, collectionId) {
    const file = event.target.files[0];
    if (file) {
      this.fileService.fileToBase64(file).subscribe({
        next: (base64Image: string) => {
          this.tempCollectionImages[collectionId] = base64Image;
        },
        error: (error) => {
          console.error("Error converting file to base64:", error);
        }
      });
    }
  }

  // Метод для сохранения нового фото промпта
  saveNewPrompt(tempId: number) {
    if (!this.editCache[tempId]) return;

    const newPromptData = this.editCache[tempId].data;
    this.photoPromptsService.createPhotoPrompt(newPromptData).subscribe({
      next: (createdPrompt) => {
        const index = this.photoPrompts.findIndex(
          (p) => p.id === Number(tempId)
        );
        if (index !== -1) {
          this.photoPrompts[index] = createdPrompt;
        }
        this.addPhotoPromptMode = false;
        delete this.editCache[tempId];
        this.loadPhotoPromptCollections();
      },
      error: (error) => {
        console.error("Error saving new prompt:", error);
      }
    });
  }

  removeCollection(id) {
    if (confirm("Are you sure want to delete collection? ")) {
      this.photoPromptsService.removePhotoPromptCollection(id).subscribe(() => {
        this.photoPromptCollections = this.photoPromptCollections.filter(
          (collection) => collection.id !== id
        );
        this.snackBar.open("The collection was deleted successfully", "Close", {
          duration: 3000,
        });
      });
    } else {
      return of(false);
    }
  }

  takeCollectionSnapshot(id) {
    this.photoPromptsService
      .getPhotoPromptCollection(id)
      .pipe(take(1))
      .subscribe((collection) => {
        navigator.clipboard.writeText(JSON.stringify(collection))
          .then(() => {
            this.snackBar.open("Collection snapshot is copied!", "Close", {
              duration: 3000,
            });
          });
      });
  }

  // Метод для отмены добавления нового фото промпта
  cancelNewPrompt(tempId: number) {
    if (!this.editCache[tempId]) return;

    if (
      this.isPromptEmpty(this.editCache[tempId].data) ||
      confirm("Do you want to discard this new prompt?")
    ) {
      this.photoPrompts = this.photoPrompts.filter((p) => p.id !== tempId);
      delete this.editCache[tempId];
      this.addPhotoPromptMode = false;
    }
  }

  saveEdit(promptId: number): void {
    const editingPrompt = this.editCache[promptId]?.data as any;
    if (!editingPrompt) return;

    if (typeof editingPrompt.content === 'string') {
      editingPrompt.content = editingPrompt.content.split('\n').filter(line => line.trim() !== '');
    }

    this.photoPromptsService.updatePhotoPrompt(promptId, editingPrompt)
      .pipe(take(1)).
      subscribe({
        next: (response) => {
          // Найти и обновить промпт в коллекции
          this.photoPromptCollections.forEach((collection) => {
            const promptIndex = collection.photoPrompts.findIndex(p => p.id === promptId);
            if (promptIndex > -1) {
              collection.photoPrompts[promptIndex] = response;
            }
          });
          this.editCache[promptId].edit = false;
        },
        error: (error) => {
          console.error("Error updating photo prompt:", error);
        }
      });
  }

  handleContentChange(promptId: number, newContent: string[]): void {
    this.editCache[promptId].data.content = newContent;
  }

  updateEditCache(): void {
    this.editCache = {};
    this.photoPrompts.forEach((prompt) => {
      this.editCache[prompt.id] = {
        edit: false,
        data: { ...prompt },
      };
    });
  }

  startEdit(promptId: number): void {
    if (!this.editCache[promptId]) {
      const prompt = this.findPromptById(promptId);
      if (prompt) {
        this.editCache[promptId] = { edit: true, data: { ...prompt } };
      }
    } else {
      this.editCache[promptId].edit = true;
    }
  }

  findPromptById(promptId: number): PhotoPrompt | undefined {
    for (const collection of this.photoPromptCollections) {
      const foundPrompt = collection.photoPrompts.find(
        (p) => p.id === promptId
      );
      if (foundPrompt) return foundPrompt;
    }
    return undefined;
  }

  cancelEdit(promptId: number): void {
    this.editCache[promptId].edit = false;
    const originalPrompt = this.findPromptById(promptId);
    if (originalPrompt) {
      this.editCache[promptId].data = { ...originalPrompt };
    }
  }

  // Вспомогательный метод для проверки, пуст ли промпт
  isPromptEmpty(prompt: PhotoPrompt) {
    return (
      !prompt.imageUrl &&
      !prompt.name &&
      !prompt.category &&
      !prompt.gender &&
      !prompt.prompt &&
      !prompt.negativePrompt &&
      !prompt.aDetailerPromptTemplate &&
      !prompt.aDetailerNegativePrompt &&
      !prompt.modelWeight
    );
  }

  // Метод для удаления фото промпта
  confirmAndDeletePrompt(id: number, collectionId: number) {
    if (
      confirm(
        "Are you sure you want to delete this prompt? This action cannot be undone."
      )
    ) {
      this.photoPromptsService.deletePhotoPrompt(id).pipe(take(1)).subscribe(
        (isDeleted) => {
          if (isDeleted) {
            this.photoPrompts = this.photoPrompts.filter((p) => p.id !== id);
            const collection = this.photoPromptCollections.find(
              (c) => c.id === collectionId
            );
            if (collection) {
              collection.photoPrompts = collection.photoPrompts.filter(
                (p) => p.id !== id
              );
            }
          }
        },
        (error) => {
          console.error("Error deleting prompt:", error);
        }
      );
    }
  }

  loadPhotoPrompts(): void {
    this.photoPromptsService.getPhotoPrompts().subscribe(
      (prompts) => {
        this.photoPrompts = prompts.sort((a, b) => a.id - b.id); // Sorting by id
        this.updateEditCache();
        this.isLoading = false;
      },
      (error) => {
        console.error("Error fetching photo prompts:", error);
        this.isLoading = false;
      }
    );
  }

  get filteredPrompts() {
    if (!this.filterText) {
      // Если фильтр пуст, возвращаем все промпты
      return this.photoPrompts;
    }

    const filterTextLower = this.filterText.toLowerCase();

    return this.photoPrompts.filter(
      (prompt) =>
        prompt.category.toLowerCase().includes(filterTextLower) ||
        prompt.name.toLowerCase().includes(filterTextLower) ||
        prompt.prompt.toLowerCase().includes(filterTextLower) ||
        (prompt.aDetailerPromptTemplate &&
          prompt.aDetailerPromptTemplate
            .toLowerCase()
            .includes(filterTextLower)) ||
        prompt.negativePrompt.toLowerCase().includes(filterTextLower)
    );
  }

  addNewCollection(): void {
    this.isNewCollectionActive = true;
  }

  saveNewCollection(): void {
    // Отправка новой коллекции в API
    this.photoPromptsService
      .createPhotoPromptCollection(this.newCollection)
      .pipe(take(1))
      .subscribe(
        (response) => {
          // Обработка успешного ответа
          this.isNewCollectionActive = false;
          this.newCollection = this.newCollectionReset;
          this.loadPhotoPromptCollections();
        },
        (error) => {
          console.error("Error creating new collection", error);
        }
      );
  }

  saveCollectionFromSnapshot(): void {
    // Отправка новой коллекции в API
    navigator.clipboard
      .readText()
      .then((text) => {
        try {
          const collection = JSON.parse(text);
          this.photoPromptsService
            .createPhotoPromptCollectionFromSnapshot(collection)
            .pipe(take(1))
            .subscribe(
              (response) => {
                // Обработка успешного ответа
                this.loadPhotoPromptCollections();
                this.snackBar.open("Collection pasted successfully!", "Close", {
                  duration: 3000,
                });
              },
              (error) => {
                console.error("Error creating collection from snapshot", error);
              }
            );
        }
        catch (error) {
          console.error("Error creating collection from snapshot", error);
          this.snackBar.open("Error creating collection from snapshot!", "Close", {
            duration: 3000,
          });
        }
      });
  }

  toggleAllAccordions(): void {
    this.isAllExpanded = !this.isAllExpanded;
    if (this.isAllExpanded) {
      this.showOnlyActive = false; // Сбросить, если все аккордеоны раскрываются
    }
  }

  toggleActiveAccordions(): void {
    this.showOnlyActive = !this.showOnlyActive;
  }

  isTempId(id: string | string): boolean {
    console.log("id", id);
    return isNaN(Number(id));
  }

  isPromptValid(prompt: PhotoPrompt): boolean {
    return !!prompt.name && !!prompt.gender && !!prompt.prompt;
  }

  onTokenSelected(token: string, promptId) {
    this.editCache[promptId].data.prompt += `, ${token}`;
  }
}
