// monitoring.service.ts
import { Injectable } from "@angular/core";
import { Observable, interval } from "rxjs";
import { ApiService, StatsByDayResponse, UserTaskResponse, UserTaskStatus } from "./api.service";
import { filter, map, startWith, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MonitoringService {
  constructor(private apiService: ApiService) {}

  getActiveTasks(count?: number): Observable<UserTaskResponse[]> {
    return this.apiService
      .getTaskList(count)
      .pipe(
        map((tasks) =>
          tasks.filter(
            (task) =>
              task.status !== UserTaskStatus.completed &&
              task.status !== UserTaskStatus.failed
          )
        )
      );
  }

  pollActiveTasks(count?: number): Observable<UserTaskResponse[]> {
    return interval(10000).pipe(
      startWith(0), // чтобы сразу сделать запрос при подписке
      switchMap(() => this.getActiveTasks(count)),
      map(tasks => tasks.filter(task => task.status !== UserTaskStatus.canceled))
    );
  }

  getStatsByDays(startDate: string, endDate: string): Observable<StatsByDayResponse> {
    return this.apiService.getStatsByDays(startDate, endDate);
  }
}
