// src/app/services/file.service.ts
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  public fileToBase64(file: File): Observable<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable((observer: Observer<string>) => {
      reader.onload = () => {
        const base64String = reader.result as string;
        observer.next(base64String.split(',')[1]);
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }
}
