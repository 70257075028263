import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  email: string;
  password: string;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  login(): void {
    this.authService
      .login({ email: this.email, password: this.password })
      .subscribe((response) => {
        this.router.navigate(["/users"]);
      });
  }
}
