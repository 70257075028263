import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { UndressSettings } from "../interfaces/common.interface";

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  name: string;
  token: string;
  userId: string;
}

export enum SubscriptionType {
  nsfw,
  avatars5,
  avatars10,
  avatars15,
  weeklySubscription,
  monthlySubscription,
  yearlySubscription,
}

export enum UserListSort {
  registration,
  activity,
  avatarsCount,
  imagesCount,
}

export interface UserAccess {
  avatarsCount: number;
  subscriptionActualType: SubscriptionType;
  generationQualities: string[];
  generationsCount: 5;
  nsfwEnabled: true;
  unlimitedAvatarsEnabled: true;
}

export interface UserResponse {
  id: string;
  nickName: string;
  email: string;
  pictureUrl: string;
  role: string;
  access: UserAccess;
  roles: string[];
  stats: {
    totalAvatarsCount: number;
    totalGenerationsCount: number;
    totalImagesCount: number;
    totalPurchasesCount: number;
  };
  avatars: {
    gender: string;
    loraName: string;
    name: string;
    sdModelName: string;
  }[];
  createdAt?: string;
  lastActiveAt?: string;
  emailIsGenerated: boolean;
  tokensAmount: number;
  userDevices: {
    platform: string;
    appVersion: string;
  }[];
}

export enum ModelOptions {
  Reliberate_v2 = "Reliberate_v2",
  Deliberate_v2 = "Deliberate_v2",
  Deliberate_v3 = "Deliberate_v3",
  Deliberate_v4 = "Deliberate_v4",
  Deliberate_v4_sfw = "Deliberate_v4_sfw",
  Reliberate_v3 = "Reliberate_v3",
  epicphotogasm_v4One4All = "epicphotogasm_v4One4All",
  lazymix_v20Fixed = "lazymix_v20Fixed",
}

export enum SamplerOptions {
  Euler_a = "Euler a",
  Euler = "Euler",
  LMS = "LMS",
  DPM2 = "DPM2",
  DPM2_a = "DPM2 a",
  DPM_2S_a = "DPM++ 2S a",
  DPM_2M = "DPM++ 2M",
  DPM_SDE = "DPM++ SDE",
  DPM_2M_SDE = "DPM++ 2M SDE",
  LMS_Karras = "LMS Karras",
  DPM2_Karras = "DPM2 Karras",
  DPM2_a_Karras = "DPM2 a Karras",
  DPM_2S_a_Karras = "DPM++ 2S a Karras",
  DPM_2M_Karras = "DPM++ 2M Karras",
  DPM_SDE_Karras = "DPM++ SDE Karras",
  DPM_2M_SDE_Karras = "DPM++ 2M SDE Karras",
  PLMS = "PLMS",
}

export enum PromptSource {
  gender = "gender",
  default = "default",
  photo = "photo",
  taskData = "taskData",
  lora = "lora",
}


export interface SkinSetting {
  name: string;
  prompt: string;
  color: string;
  url: string;
}

export interface BodySetting {
  name: string;
  visibleName: string;
  prompt: string;
  image: string;
}

export interface StableDiffusionSettings {
  defaultModel: ModelOptions;
  defaultSampler: SamplerOptions;
  defaultStepsSmall: number;
  defaultStepsMedium: number;
  defaultStepsLarge: number;
  minPhotosForTraining: number;
  defaultCfgScale: number;
  randomSeed: boolean;
  defaultMalePrompt: string;
  defaultFemalePrompt: string;
  defaultPrompt: string;
  defaultNegativePrompt: string;
  defaultModelWeight: number;
  textPromptOrder: PromptSource[];
  photoPromptOrder: PromptSource[];
  nsfwFilterValue: number;
  nsfwIterationsCount: number;
  skinSettings: SkinSetting[];
  bodySettings: BodySetting[];
}

export enum UserTaskType {
  Training = "training",
  Generation = "generation",
  ImageConvert = "imageConvert",
  GalleryGenerate = "galleryGenerate",
}

export enum UserTaskStatus {
  pending = "pending",
  inProgress = "inProgress",
  completed = "completed",
  failed = "failed",
  canceled = "canceled",
}

export interface UserTaskResponse {
  id?: string; // nullable
  type: UserTaskType;
  status: UserTaskStatus;
  progress: number; // float
  createdAt: string; // date-time
  queueCount?: number; // nullable, int32
  userId: string;
  priority: string;
}

// src/app/models/photo-prompt-response.model.ts

export interface PhotoPrompt {
  id?: number; // Optional for creation
  name: string;
  category: string;
  gender: "male" | "female" | "preferNotToSay";
  prompt: string;
  negativePrompt: string;
  image?: string;
  imageUrl?: string; // Optional for creation
  // steps?: number;
  // seed?: number;
  // denoisingStrength?: number;
  // samplerName?: string;
  // cfgScale?: number;
  // width?: number;
  // height?: number;
  // hrScale?: number;
  // hrUpscaler?: string;
  aDetailerPromptTemplate?: string;
  adDenoisingStrength: number;
  aDetailerEnabled: boolean;
  aDetailerMaskBlur: number;
  aDetailerNegativePrompt?: string;
  modelWeight?: number;
  priority?: number;
  photoPromptCollectionId?: number;
  filterType: 'none' | 'blackAndWhite' | 'sepia';
  cfgScale?: number,
  height?: number,
  width?: number,
  samplerName?: SamplerOptions,
  steps?: number
  hrScale?: number;
  hrUpscaler?: string;
  reactorEnabled?: boolean;
  description?: string;
  content?: string[];
  exampleImageUrls?: string[];
}

export interface PhotoPromptCollectionResponse {
  id: number;
  name: string;
  priority: number;
  isActive: boolean;
  isNsfw: boolean;
  featured: boolean;
  featuredStartDate?: string;
  featuredEndDate?: string;
  photoPrompts: PhotoPrompt[];
  imageUrl?: string;
}

export interface PhotoPromptCollectionUpdateRequest {
  id?: number;
  name: string;
  priority: number;
  isActive: boolean;
  isNsfw: boolean;
  featured: boolean;
  featuredStartDate: string; // Дата в формате ISO
  featuredEndDate: string; // Дата в формате ISO
  image?: string;
}

export interface PhotoStyleResponse {
  id: number;
  name: string;
  maleImageUrl?: string;
  femaleImageUrl?: string;
  prompt: string;
  negativePrompt: string;
  isActive: boolean;
  priority: number;
}

export interface PhotoStyleRequest {
  name?: string;
  maleImage?: string;
  femaleImage?: string;
  isActive: boolean;
  priority: number;
  prompt: string;
  negativePrompt: string;
}

export interface AiArtResponse {
  id: number;
  name: string;
  gender: "male" | "female" | "preferNotToSay";
  prompt: string;
  negativePrompt: string;
  image: string;
  priority: number;
}

export interface AiArtRequest {
  name?: string;
  gender?: "male" | "female" | "preferNotToSay";
  prompt?: string;
  negativePrompt?: string;
  image?: string; // Base64 encoded image
  priority?: number;
}

export interface StatsByDayResponse {
  days: {
    [key: string]: {
      [key: string]: number;
    };
  };
  total: {
    [key: string]: number;
  };
}

export interface TokensSettings {
  trainingCost: number;
  generationCost: number;
  dailyTokensAward: number;
}

export interface ChangeTokensRequest {
  userId: string;
  tokensAmount: number;
}

export interface ChangeTokensResponse {
  tokensAmount: number;
}

export interface AuthenticationSettings {
  googleEnabled: boolean;
  appleEnabled: boolean;
  twitterEnabled: boolean;
}


export interface TrainingConfigResponse {
  loraTrainSettings: string;
}

export interface TrainingSettingsVersion {
  value: { loraTrainSettings: string };
  version: number;
  majorVersion: number;
  createdAt: string;
  userId: string;
}

export interface PhotoSize {
  id: number;
  name: string;
  width: number;
  height: number;
  isPaid: boolean;
  priority: number;
  imageUrl: string;
}

export interface PhotoSizeRequest {
  name: string;
  width: number;
  height: number;
  isPaid: boolean;
  priority: number;
  image: string; // Assuming this is a base64 encoded string of the image
}



@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiHost = environment.apiUrl;
  baseUrl = `${this.apiHost}/api/v1`;

  constructor(private http: HttpClient) {}

  login(data: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.baseUrl}/auth/signin`, data);
  }

  getUsers(
    count = 20,
    query = "",
    sort: UserListSort = UserListSort.activity
  ): Observable<UserResponse[]> {
    return this.http.get<UserResponse[]>(
      `${this.baseUrl}/admin-panel/user/list?count=${count}&query=${query}&sort=${UserListSort[sort]}`
    );
  }

  deleteUser(id: string): Observable<UserResponse[]> {
    return this.http.delete<UserResponse[]>(
      `${this.baseUrl}/admin-panel/user/${id}`
    );
  }

  purchaseItem(userId: string, purchase: SubscriptionType): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin-panel/user/purchase`, {
      mode: "sale",
      purchase,
      userId,
    });
  }

  getSDConfig(): Observable<StableDiffusionSettings> {
    return this.http.get<StableDiffusionSettings>(
      `${this.baseUrl}/admin-panel/config/StableDiffusionSettings`
    );
  }

  saveSDConfig(data: StableDiffusionSettings): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/admin-panel/config/StableDiffusionSettings`,
      {
        ...data,
      }
    );
  }

  getCurrentTaskList(data: StableDiffusionSettings): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/admin-panel/config/StableDiffusionSettings`,
      {
        ...data,
      }
    );
  }

  getTaskList(count?: number): Observable<UserTaskResponse[]> {
    const queryParams = count ? `?count=${count}` : "";
    return this.http.get<UserTaskResponse[]>(
      `${this.baseUrl}/admin-panel/task/list${queryParams}`
    );
  }

  public getPhotoPrompts(): Observable<PhotoPrompt[]> {
    return this.http.get<PhotoPrompt[]>(
      `${this.baseUrl}/admin-panel/photo-prompt/list`
    );
  }

  // В PhotoPromptsService

  public getPhotoPromptCollections(): Observable<
    PhotoPromptCollectionResponse[]
  > {
    return this.http.get<PhotoPromptCollectionResponse[]>(
      `${this.baseUrl}/admin-panel/photo-prompt-collection/list`
    );
  }

  public getPhotoPromptCollection(id: number): Observable<
    PhotoPromptCollectionResponse
  > {
    return this.http.get<PhotoPromptCollectionResponse>(
      `${this.baseUrl}/admin-panel/photo-prompt-collection/${id}`
    );
  }

  updatePhotoPromptCollection(
    collection: PhotoPromptCollectionUpdateRequest
  ): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/admin-panel/photo-prompt-collection/${collection.id}`,
      collection
    );
  }

  createPhotoPromptCollection(
    collection: PhotoPromptCollectionUpdateRequest
  ): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/admin-panel/photo-prompt-collection/`,
      collection
    );
  }

  createPhotoPromptCollectionFromSnapshot(
    collection: PhotoPromptCollectionResponse
  ): Observable<PhotoPromptCollectionResponse> {
    return this.http.post<PhotoPromptCollectionResponse>(
      `${this.baseUrl}/admin-panel/photo-prompt-collection/from-snapshot`,
      collection
    );
  }

  removePhotoPromptCollection(id: number): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}/admin-panel/photo-prompt-collection/${id}`
    );
  }

  updatePhotoPrompt(id: number, data: PhotoPrompt): Observable<PhotoPrompt> {
    console.log('data', data);
    return this.http.put<PhotoPrompt>(
      `${this.baseUrl}/admin-panel/photo-prompt/${id}`,
      { ...data }
    );
  }

  createPhotoPrompt(data: PhotoPrompt): Observable<PhotoPrompt> {
    return this.http.post<PhotoPrompt>(
      `${this.baseUrl}/admin-panel/photo-prompt`,
      data
    );
  }

  deletePhotoPrompt(id: number): Observable<any> {
    return this.http
      .delete<any>(`${this.baseUrl}/admin-panel/photo-prompt/${id}`)
      .pipe(map(() => true));
  }

  getPhotoStyles(): Observable<PhotoStyleResponse[]> {
    return this.http.get<PhotoStyleResponse[]>(
      `${this.baseUrl}/admin-panel/photo-style/list`
    );
  }

  updatePhotoStyle(
    id: number,
    data: PhotoStyleRequest
  ): Observable<PhotoStyleResponse> {
    return this.http.put<PhotoStyleResponse>(
      `${this.baseUrl}/admin-panel/photo-style/${id}`,
      data
    );
  }

  createPhotoStyle(data: PhotoStyleRequest): Observable<PhotoStyleResponse> {
    return this.http.post<PhotoStyleResponse>(
      `${this.baseUrl}/admin-panel/photo-style/`,
      data
    );
  }

  getAiArts(): Observable<AiArtResponse[]> {
    return this.http.get<AiArtResponse[]>(
      `${this.baseUrl}/admin-panel/ai-art/list`
    );
  }

  updateAiArt(id: number, data: any): Observable<AiArtResponse> {
    return this.http.put<AiArtResponse>(
      `${this.baseUrl}/admin-panel/ai-art/${id}`,
      data
    );
  }

  createAiArt(data: AiArtRequest): Observable<AiArtResponse> {
    return this.http.post<AiArtResponse>(
      `${this.baseUrl}/admin-panel/ai-art`,
      data
    );
  }

  getStatsByDays(
    startDate: string,
    endDate: string
  ): Observable<StatsByDayResponse> {
    return this.http.get<StatsByDayResponse>(
      `${this.baseUrl}/admin-panel/task/stats/by-day?startdate=${startDate}&enddate=${endDate}`
    );
  }

  getTokensSettings(): Observable<TokensSettings> {
    return this.http.get<TokensSettings>(`${this.baseUrl}/admin-panel/config/TokensSettings`);
  }
  
  updateTokensSettings(settings: TokensSettings): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin-panel/config/TokensSettings`, settings);
  }

  changeUserTokens(request: ChangeTokensRequest): Observable<ChangeTokensResponse> {
    return this.http.patch<ChangeTokensResponse>(`${this.baseUrl}/admin-panel/user/change-tokens`, request);
  }

  // Получение настроек аутентификации
  getAuthenticationSettings(): Observable<AuthenticationSettings> {
    return this.http.get<AuthenticationSettings>(
      `${this.baseUrl}/admin-panel/config/AuthenticationSettings`
    );
  }

  // Обновление настроек аутентификации
  updateAuthenticationSettings(data: AuthenticationSettings): Observable<AuthenticationSettings> {
    return this.http.post<AuthenticationSettings>(
      `${this.baseUrl}/admin-panel/config/AuthenticationSettings`,
      data
    );
  }


  getTrainingConfig(): Observable<TrainingConfigResponse> {
    return this.http.get<TrainingConfigResponse>(
      `${this.baseUrl}/admin-panel/config/TrainingSettings`
    );
  }

  saveTrainingConfig(config: { loraTrainSettings: string }): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin-panel/config/TrainingSettings`, config);
  }

  getTrainingConfigList(): Observable<TrainingSettingsVersion[]> {
    return this.http.get<TrainingSettingsVersion[]>(`${this.baseUrl}/admin-panel/config/TrainingSettings/list`);
  }

  resetTrainingConfig(version: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin-panel/config/TrainingSettings/reset/${version}`, {});
  }



  // Get list of photo sizes
  getPhotoSizes(): Observable<PhotoSize[]> {
    return this.http.get<PhotoSize[]>(`${this.baseUrl}/admin-panel/photo-size/list`);
  }

  // Create a photo size
  createPhotoSize(photoSizeRequest: PhotoSizeRequest): Observable<PhotoSize> {
    return this.http.post<PhotoSize>(`${this.baseUrl}/admin-panel/photo-size`, photoSizeRequest);
  }

  // Update a photo size
  updatePhotoSize(id: number, photoSizeRequest: PhotoSizeRequest): Observable<PhotoSize> {
    return this.http.put<PhotoSize>(`${this.baseUrl}/admin-panel/photo-size/${id}`, photoSizeRequest);
  }

  // Delete a photo size
  deletePhotoSize(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/admin-panel/photo-size/${id}`);
  }

  getOpenApiConfig(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/admin-panel/schema`);
  }

  getUndressSettings(): Observable<UndressSettings> {
    return this.http.get<UndressSettings>(`${this.baseUrl}/admin-panel/config/UndressSettings`);
  }

  updateUndressSettings(settings: UndressSettings): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin-panel/config/UndressSettings`, settings);
  }

  setAdminRole(userId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/admin-panel/user/role`, {userId, role: "Admin"});
  }
}
