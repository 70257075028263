// clipboard.service.ts

import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(private snackBar: MatSnackBar) {}

  copyToClipboard(value: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.showSnackbar('Скопировано в буфер обмена!');
  }

  private showSnackbar(message: string): void {
    this.snackBar.open(message, 'Закрыть', {
      duration: 3000
    });
  }
}
