import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TokenSelectorComponent } from "./token-selector.component";

@NgModule({
  declarations: [TokenSelectorComponent],
  imports: [CommonModule],
  exports: [TokenSelectorComponent],
})
export class TokenSelectorModule {}
