import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectName } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  isProduction = environment.production;
  title: String = '';
  projectName: String = '';
  environment = environment;

  ngOnInit(): void {
    switch(environment.name) {
      case ProjectName.virtwin:
        this.title = 'VIrTwin Admin Panel';
        this.projectName = 'virtwin';
        break;
      case ProjectName.xtwin:
        this.title = 'XTwin Admin Panel';
        this.projectName = 'xtwin';
      case ProjectName.exposer:
        this.title = 'Exposer Admin Panel';
        this.projectName = 'exposer';
      case ProjectName.dressmy:
        this.title = 'Dressmy Admin Panel';
        this.projectName = 'dressmy';
    }
  }
}
