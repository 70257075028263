// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-male {
  border: 2px solid blue;
}

.border-female {
  border: 2px solid pink;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/photo-styles/photo-styles.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AACA;EACI,sBAAA;AAEJ","sourcesContent":[".border-male {\n    border: 2px solid blue;\n}\n.border-female {\n    border: 2px solid pink;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
