export enum ProjectName {
  virtwin,
  xtwin,
  exposer,
  dressmy,
  lafary
}
export interface EnvironmentModel {
  production: boolean;
  apiUrl: string;
  url: string;
  crossTestProdSiblingUrl: string;
  name: ProjectName;
  heliosUrl: string;
}
