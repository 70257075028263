import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService, TokensSettings } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class TokenisationService {
  constructor(private apiService: ApiService) {}

  getTokensSettings(): Observable<TokensSettings> {
    return this.apiService.getTokensSettings();
  }
  
  updateTokensSettings(settings: TokensSettings): Observable<any> {
    return this.apiService.updateTokensSettings(settings);
  }
}
