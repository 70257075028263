import { SubscriptionType, UserListSort, UserResponse } from "./../../services/api.service";
import { Component, OnInit } from "@angular/core";
import { first, map, take } from "rxjs/operators";
import { ClipboardService } from "src/app/helpers/clipboard.service";
import { UserService } from "src/app/services/user.service";

export interface User {
  userResponse: UserResponse;
  isEditing: boolean;
}

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  users: User[];
  public subscriptionTypes = Object.values(SubscriptionType).filter(
    (value) => typeof value === "string"
  );
  searchQuery: string = "";
  displayCount: number = 20;
  sortByRegistration: boolean = false;
  sortByActivity: boolean = false;
  sortMethod: string;
  userListSort = UserListSort;

  constructor(
    private userService: UserService,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  onSearch() {
    this.getUsers(undefined, this.searchQuery);
  }

  onApply(sort: string) {

    this.getUsers(
      this.displayCount,
      this.searchQuery,
      UserListSort[sort]
    );
  }

  getUsers(count = 20, query = "", sort?: UserListSort) {
    this.userService.getUsers(count, query, sort).pipe(
      map((res) => {
        return res.map((r) => ({ userResponse: r, isEditing: false }));
      }),
      take(1)
    ).subscribe(res => {
      this.users = res;
    });
  }

  deleteUser(id: string) {
    if (confirm("Are you sure want to delete this user?")) {
      this.userService.deleteUser(id).pipe(
        take(1)
      ).subscribe(res => {
        this.getUsers();
      });
    }


  }

  setAdmin(id: string) {
    if (confirm("Are you sure want to set Admin role to this user?")) {
      this.userService.setAdmin(id).pipe(
        take(1)
      ).subscribe(res => {
        this.getUsers();
      });
    }

  }

  toggleEdit(user) {
    user.isEditing = !user.isEditing;
    if (user.isEditing) {
      user.selectedSubscriptionType = user.subscriptionActualType;
    }
  }

  setSubscriptionType(user: User) {
    this.userService
      .setSubscriptionType(
        user.userResponse.id,
        user.userResponse.access.subscriptionActualType
      )
      .pipe(first())
      .subscribe((res) => {
        this.toggleEdit(user);
      });
  }

  getLastQuality(user): string {
    const qualities = user.userResponse.access.generationQualities;
    if (qualities.includes("large")) return "large";
    if (qualities.includes("medium")) return "medium";
    if (qualities.includes("small")) return "small";
    return "";
  }

  onCopyClick(value: string): void {
    this.clipboardService.copyToClipboard(value);
  }

  getBackgroundColorClass(totalImages: number): string {
    if (totalImages <= 10) return "white-bg";
    if (totalImages <= 50) return "light-yellow-bg";
    if (totalImages <= 100) return "yellow-bg";
    if (totalImages <= 500) return "orange-bg";
    if (totalImages <= 1000) return "red-bg";
    return "brown-bg";
  }

  changeTokens(userId, tokensAmount) {
    this.userService.changeUserTokens({
      userId, tokensAmount
    }).pipe(take(1)).subscribe(res => this.users.find(user => user.userResponse.id === userId).userResponse.tokensAmount = res.tokensAmount);
  }
}
