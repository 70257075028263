// user.service.ts

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService, ChangeTokensRequest, ChangeTokensResponse, SubscriptionType, UserListSort, UserResponse } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService) { }

  getUsers(count: number, query: string, sort?: UserListSort): Observable<UserResponse[]> {
    return this.apiService.getUsers(count , query, sort);
  }

  deleteUser(id: string): Observable<UserResponse[]> {
    return this.apiService.deleteUser(id);
  }

  setAdmin(id: string): Observable<UserResponse[]> {
    return this.apiService.setAdminRole(id);
  }

  setSubscriptionType(userId: string, subscriptionType: SubscriptionType): Observable<any> {
    return this.apiService.purchaseItem(userId, subscriptionType);
  }
  changeUserTokens(requestData: ChangeTokensRequest): Observable<ChangeTokensResponse> {
    return this.apiService.changeUserTokens(requestData);
  }
}
