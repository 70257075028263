import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  PhotoStyleRequest,
  PhotoStyleResponse,
} from "src/app/services/api.service";
import { PhotoStylesService } from "src/app/services/photo-styles.service";

@Component({
  selector: "app-photo-styles",
  templateUrl: "./photo-styles.component.html",
  styleUrls: ["./photo-styles.component.scss"],
})
export class PhotoStylesComponent implements OnInit {
  photoStyles: PhotoStyleResponse[] = [];
  editingStyleId: number | null = null;
  updatedName: string = "";
  editingIsActive: boolean;
  editingPriority: number;
  editingPrompt: string = "";
  editingNegativePrompt: string = "";
  prompt: string;
  negativePrompt: string;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  currentEditingGender: "male" | "female" | null = null;
  currentEditingStyleId: number | null = null;
  maleImageBase64: string | null = null;
  femaleImageBase64: string | null = null;

  constructor(private photoStylesService: PhotoStylesService) {}

  ngOnInit() {
    this.photoStylesService.getPhotoStyles().subscribe((styles) => {
      this.photoStyles = styles;
    });
  }

  beginEdit(style: PhotoStyleResponse) {
    this.editingStyleId = style.id;
    this.updatedName = style.name;
    this.editingIsActive = style.isActive;
    this.editingPriority = style.priority;
    this.editingPrompt = style.prompt; // Инициализируем редактируемый prompt
    this.editingNegativePrompt = style.negativePrompt; // Инициализируем редактируемый negativePrompt
  }

  saveChanges(styleId: number) {
    const updateData: PhotoStyleRequest = {
      name: this.updatedName,
      maleImage: this.maleImageBase64,
      femaleImage: this.femaleImageBase64,
      isActive: this.editingIsActive,
      priority: this.editingPriority,
      prompt: this.editingPrompt, // Используем обновленное значение
      negativePrompt: this.editingNegativePrompt, // Используем обновленное значение
    };

    if (styleId === -1) {
      // Создание нового стиля
      this.photoStylesService.createPhotoStyle(updateData).subscribe(() => {
        this.loadStyles();
      });
    } else {
      // Обновление существующего стиля
      this.photoStylesService
        .updatePhotoStyle(styleId, updateData)
        .subscribe(() => {
          this.loadStyles();
        });
    }

    this.cancelEdit();
  }

  loadStyles() {
    this.photoStylesService.getPhotoStyles().subscribe((styles) => {
      this.photoStyles = styles;
    });
  }

  openFileChooser(gender: "male" | "female", styleId: number) {
    this.currentEditingGender = gender;
    this.currentEditingStyleId = styleId;
    this.fileInput.nativeElement.click();
  }

  uploadImage(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let base64Image = e.target.result as string;

        // Удаление префикса MIME-типа
        const base64ImageWithoutPrefix = base64Image.split(",")[1];

        if (this.currentEditingGender === "male") {
          this.maleImageBase64 = base64ImageWithoutPrefix;
        } else if (this.currentEditingGender === "female") {
          this.femaleImageBase64 = base64ImageWithoutPrefix;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  cancelEdit() {
    this.editingStyleId = null;
    this.maleImageBase64 = null;
    this.femaleImageBase64 = null;
  }

  addNewStyle() {
    const newStyle: PhotoStyleResponse = {
      id: -1, // временный ID для нового стиля
      name: "",
      maleImageUrl: "",
      femaleImageUrl: "",
      prompt: '',
      negativePrompt: '',
      priority: 0,
      isActive: true
    };
    this.photoStyles.unshift(newStyle);
    this.beginEdit(newStyle);
  }
}
