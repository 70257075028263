// token-selector.component.ts
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { JsonDataService } from "src/app/services/json-data-service";

@Component({
  selector: "app-token-selector",
  styleUrls: ["./token-selector.component.scss"],
  template: `
    <div class="token-selector-container">
      <button
        type="button"
        class="btn btn-primary btn-small"
        (click)="toggleList()"
      >
        Tokens
      </button>
      <div *ngIf="showList" class="token-list row">
        <div
          class="col-sm-4 col-6"
          *ngFor="let item of jsonData"
          (click)="selectGroup(item)"
        >
          <div class="list-group-item">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div *ngIf="showSubList" class="sub-token-list row">
        <button
          type="button"
          class="btn btn-secondary btn-small"
          (click)="backToList()"
        >
          Back
        </button>
        <div
          class="col-sm-4 col-6"
          *ngFor="let subItem of selectedSubGroup"
          (click)="selectItem(subItem.name)"
        >
          <div class="list-group-item">
            {{ subItem.name }}
          </div>
        </div>
      </div>
    </div>
  `,
})
export class TokenSelectorComponent implements OnInit {
  jsonData: any[] = [];
  selectedSubGroup: string[] = [];
  showList = false;
  showSubList = false;

  @Output() tokenSelected = new EventEmitter<string>();

  constructor(private jsonDataService: JsonDataService) {}

  ngOnInit(): void {
    this.jsonDataService.getJsonData().subscribe((data) => {
      this.jsonData = data;
    });
  }

  toggleList() {
    this.showList = !this.showList;
    if (!this.showList) {
      this.showSubList = false;
    }
  }

  selectGroup(item: any) {
    if (item.list && item.list.length) {
      this.selectedSubGroup = item.list;
      this.showSubList = true;
    } else {
      this.tokenSelected.emit(item.name);
      this.showList = false;
    }
  }

  selectItem(subItem: string) {
    this.tokenSelected.emit(subItem);
    this.showList = false;
    this.showSubList = false;
  }

  backToList() {
    this.showSubList = false;
    this.showList = true;
  }
}
