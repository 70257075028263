import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { UsersComponent } from './pages/users/users.component';
import { StableDiffusionConfigComponent } from './pages/stable-diffusion-config/stable-diffusion-config.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from 'src/interceptors/auth-interceptor';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import { PhotoPromptsComponent } from './pages/photo-prompts/photo-prompts.component';
import { PhotoStylesComponent } from './pages/photo-styles/photo-styles.component';
import { AiArtsComponent } from './pages/ai-arts/ai-arts.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TokenSelectorModule } from './components/token-selector/token-selector.module';
import { TokenisationComponent } from './pages/tokenisation/tokenisation.component';
import { AuthenticationSettingsComponent } from './pages/authentication-settings/authentication-settings.component';
import { PhotoSizeModule } from './pages/photo-size/photo-size.module';
import { FeatherModule } from 'angular-feather';
import { Home, File, Menu } from 'angular-feather/icons';
import { ArrayToStringPipe } from './pipes/array-to-string.pipe';
import { TextareaContentDirective } from './directives/content-array.directive';
import { UndresserConfigComponent } from './pages/undresser-config/undresser-config.component';

// Select some icons (use an object, not an array)
const icons = {
  File,
  Home,
  Menu
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsersComponent,
    StableDiffusionConfigComponent,
    MonitoringComponent,
    PhotoPromptsComponent,
    PhotoStylesComponent,
    AiArtsComponent,
    TokenisationComponent,
    AuthenticationSettingsComponent,
    TextareaContentDirective,
    UndresserConfigComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    DragDropModule,
    NgxChartsModule,
    TokenSelectorModule,
    PhotoSizeModule,
    FeatherModule.pick(icons)
  ],
  exports: [FeatherModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
