// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  cursor: grab;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/stable-diffusion-config/stable-diffusion-config.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACJ","sourcesContent":[".draggable-item {\n    padding: 10px;\n    border: 1px solid #ddd;\n    margin-bottom: 5px;\n    cursor: grab;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
