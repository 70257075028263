import { Component, OnInit } from '@angular/core';
import { ApiService, PhotoSize, PhotoSizeRequest } from 'src/app/services/api.service';

@Component({
  selector: 'app-photo-size',
  templateUrl: './photo-size.component.html',
  styleUrls: ['./photo-size.component.scss']
})
export class PhotoSizeComponent implements OnInit {
  photoSizes: PhotoSize[] = [];
  currentPhotoSize: PhotoSizeRequest = this.getEmptyPhotoSize();
  editMode: boolean = false;
  selectedPhotoSizeId: number | null = null;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.loadPhotoSizes();
  }

  getEmptyPhotoSize(): PhotoSizeRequest {
    return { name: '', width: 0, height: 0, isPaid: false, priority: 0, image: '' };
  }

  loadPhotoSizes(): void {
    this.apiService.getPhotoSizes().subscribe(photoSizes => this.photoSizes = photoSizes);
  }

  createOrUpdatePhotoSize(): void {
    if (this.editMode) {
      this.apiService.updatePhotoSize(this.selectedPhotoSizeId, this.currentPhotoSize).subscribe(() => {
        this.loadPhotoSizes();
        this.clearForm();
      });
    } else {
      this.apiService.createPhotoSize(this.currentPhotoSize).subscribe(() => {
        this.loadPhotoSizes();
        this.clearForm();
      });
    }
  }

  editPhotoSize(photoSize: PhotoSize): void {
    this.currentPhotoSize = { ...photoSize, image: '' }; // Assuming we don't have the base64 image string in the list
    this.editMode = true;
    this.selectedPhotoSizeId = photoSize.id;
  }

  deletePhotoSize(id: number): void {
    this.apiService.deletePhotoSize(id).subscribe(() => this.loadPhotoSizes());
  }

  clearForm(): void {
    this.currentPhotoSize = this.getEmptyPhotoSize();
    this.editMode = false;
    this.selectedPhotoSizeId = null;
  }

  convertToBase64(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.currentPhotoSize.image = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
}