// src/app/services/photo-prompts.service.ts
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  ApiService,
  PhotoPrompt,
  PhotoPromptCollectionResponse,
  PhotoPromptCollectionUpdateRequest,
} from "./api.service";
import { ClipboardService } from "../helpers/clipboard.service";

@Injectable({
  providedIn: "root",
})
export class PhotoPromptsService {
  constructor(
    private apiService: ApiService,
    private clipboardService: ClipboardService
  ) {}

  public getPhotoPrompts(): Observable<PhotoPrompt[]> {
    return this.apiService.getPhotoPrompts();
  }

  updatePhotoPrompt(id: number, data: PhotoPrompt): Observable<PhotoPrompt> {
    return this.apiService.updatePhotoPrompt(id, data);
  }

  getPhotoPromptCollections(): Observable<PhotoPromptCollectionResponse[]> {
    return this.apiService.getPhotoPromptCollections();
  }

  getPhotoPromptCollection(id: number): Observable<PhotoPromptCollectionResponse> {
    return this.apiService.getPhotoPromptCollection(id);
  }

  updatePhotoPromptCollection(
    collection: PhotoPromptCollectionUpdateRequest
  ): Observable<PhotoPromptCollectionResponse[]> {
    return this.apiService.updatePhotoPromptCollection(collection);
  }

  createPhotoPromptCollection(
    collection: PhotoPromptCollectionUpdateRequest
  ): Observable<any> {
    return this.apiService.createPhotoPromptCollection(collection);
  }

  createPhotoPromptCollectionFromSnapshot(
    collection: PhotoPromptCollectionResponse
  ): Observable<PhotoPromptCollectionResponse> {
    return this.apiService.createPhotoPromptCollectionFromSnapshot(collection);
  }

  createPhotoPrompt(data: PhotoPrompt): Observable<PhotoPrompt> {
    return this.apiService.createPhotoPrompt(data);
  }

  removePhotoPromptCollection(id: number): Observable<any> {
    return this.apiService.removePhotoPromptCollection(id);
  }

  deletePhotoPrompt(id: number): Observable<boolean> {
    return this.apiService.deletePhotoPrompt(id);
  }
}
