import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TokensSettings } from 'src/app/services/api.service';
import { TokenisationService } from 'src/app/services/tokenisation.service';

@Component({
  selector: 'app-tokenisation',
  templateUrl: './tokenisation.component.html',
  styleUrls: ['./tokenisation.component.scss']
})
export class TokenisationComponent implements OnInit {
  tokenSettings: TokensSettings;

  constructor(private tokenisationService: TokenisationService, private snackBar: MatSnackBar) {}
  Object = Object;

  ngOnInit(): void {
    this.tokenisationService.getTokensSettings().subscribe((settings) => {
      this.tokenSettings = settings;
    });
  }

  saveChanges() {
    this.tokenisationService.updateTokensSettings(this.tokenSettings).subscribe(
      (res) => {
        this.snackBar.open("Settings Updated", "Close", { duration: 2000 });
      },
      (error) => {
        console.error("Error updating settings:", error);
      }
    );
  }
}
