import { Directive, ElementRef, EventEmitter, HostListener, Input, Optional, Output, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTextareaContent]'
})
export class TextareaContentDirective {
  private _content: string[];

  @Output() contentChange = new EventEmitter<string[]>();

  constructor(private renderer: Renderer2, private elementRef: ElementRef, @Optional() private control: NgControl) { }

  @Input()
  set content(value: string[]) {
    this._content = value;
    this.updateTextarea(value);
  }

  get content(): string[] {
    return this._content;
  }

  updateTextarea(content: string[]): void {
    if (content) {
      const text = content.join('\n');
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', text);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const lines = value.split('\n').filter(line => line.trim() !== '');
    this.contentChange.emit(lines);  // Отправка обновленных данных обратно в компонент
  }
}
