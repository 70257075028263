import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MonitoringService } from "src/app/services/monitoring.service";
import {
  StatsByDayResponse,
  UserTaskResponse,
  UserTaskType,
} from "src/app/services/api.service";

@Component({
  selector: "app-monitoring",
  templateUrl: "./monitoring.component.html",
  styleUrls: ["./monitoring.component.scss"],
})
export class MonitoringComponent implements OnInit, OnDestroy {
  activeTasks: UserTaskResponse[] = [];
  taskCounts: { [key in UserTaskType]?: number } = {};
  taskTypes: string[] = []; // Holds the task types for the template
  overallCount: number = 0;
  loaderValue: number = 100;
  private pollingSubscription: Subscription;
  private loaderSubscription: Subscription;
  startDate: string;
  endDate: string;
  chartData: any[] = [];
  colorScheme = {
    domain: ["red", "blue", "yellow", "green", "purple", "orange", "grey"], // Примерные цвета
  };

  constructor(private monitoringService: MonitoringService) {}

  ngOnInit(): void {
    // Start polling for active tasks every 10 seconds
    this.monitoringService.pollActiveTasks().subscribe({
      next: (tasks) => {
        this.activeTasks = tasks;
        this.updateTaskCounts(tasks);
        this.loaderValue = 100; // Reset the loader progress when data is received
      },
      error: (error) => {
        console.error("Error fetching tasks:", error);
      },
    });

    // Timer for updating the loading indicator
    this.loaderSubscription = timer(0, 100).subscribe(() => {
      this.loaderValue = Math.max(this.loaderValue - 1, 0);
    });

    // Init dayly stats
    // Инициализация дат, например, сегодняшняя и вчерашняя даты
    // Установка даты начала и конца для последней недели
    let today = new Date();
    this.endDate = this.formatDate(today);
    let lastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 30
    );
    this.startDate = this.formatDate(lastMonth);

    this.onShowClick(); // Автоматически загрузить данные при инициализации
  }

  ngOnDestroy(): void {
    // Unsubscribe from subscriptions when the component is destroyed
    this.pollingSubscription && this.pollingSubscription.unsubscribe();
    this.loaderSubscription && this.loaderSubscription.unsubscribe();
  }

  // Helper method to update task counts by type
  private updateTaskCounts(tasks: UserTaskResponse[]): void {
    this.overallCount = tasks.length;
    this.taskCounts = {}; // Reset taskCounts before filling it again
    this.taskTypes = []; // Clear previous task types

    // Initialize all counts with zero
    for (const typeKey in UserTaskType) {
      const type = UserTaskType[typeKey];
      this.taskCounts[type] = 0;
      this.taskTypes.push(type); // Store task types for the template
    }
    // Count tasks for each type
    tasks.forEach((task) => {
      if (task.type in this.taskCounts) {
        this.taskCounts[task.type]++;
      }
    });
  }

  onShowClick(): void {
    this.monitoringService
      .getStatsByDays(this.startDate, this.endDate)
      .subscribe((data) => {
        this.chartData = this.transformDataForChart(data);
      });
  }

  private transformDataForChart(data: StatsByDayResponse): any[] {
    let chartData = [];

    Object.keys(data.days).forEach((date) => {
      let dateData = data.days[date];

      Object.keys(dateData).forEach((key) => {
        let entry = chartData.find((e) => e.name === key);
        if (!entry) {
          entry = { name: key, series: [] };
          chartData.push(entry);
        }
        entry.series.push({ name: new Date(date), value: dateData[key] });
      });
    });

    return chartData;
  }

  private formatDate(date: Date): string {
    return date.toISOString().split("T")[0];
  }
}
