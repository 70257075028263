import { filter, first } from "rxjs/operators";
import { SDConfigService } from "./../../services/sd-config.service";
import { Component, OnInit } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import {
  ModelOptions,
  SamplerOptions,
  StableDiffusionSettings,
} from "src/app/services/api.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-stable-diffusion-config",
  templateUrl: "./stable-diffusion-config.component.html",
  styleUrls: ["./stable-diffusion-config.component.scss"],
})
export class StableDiffusionConfigComponent implements OnInit {
  configData: StableDiffusionSettings;
  Object = Object;
  modelOptionsArray = Object.values(ModelOptions);
  samplerOptionsArray = Object.values(SamplerOptions);

  constructor(
    private sDConfigService: SDConfigService,
    private snackBar: MatSnackBar
  ) {
    console.log('MODELS', this.modelOptionsArray);
  }

  isString(value: any): boolean {
    return typeof value === "string";
  }

  isNumber(value: any): boolean {
    return typeof value === "number";
  }

  isBoolean(value: any): boolean {
    return typeof value === "boolean";
  }

  ngOnInit() {
    this.sDConfigService
      .getSDConfig()
      .pipe(first())
      .subscribe((res) => { });
    this.sDConfigService.configData$
      .pipe(
        filter((data) => !!data), // это исключит null значение
        first()
      )
      .subscribe((res) => {
        this.configData = res;
      });
  }

  drop(event: CdkDragDrop<any[]>, listType: string) {
    if (event.previousContainer === event.container) {
      if (listType === 'skinSettings') {
        moveItemInArray(this.configData.skinSettings, event.previousIndex, event.currentIndex);
      } else if (listType === 'bodySettings') {
        moveItemInArray(this.configData.bodySettings, event.previousIndex, event.currentIndex);
      }
    } else {
      // Если есть перетаскивание между разными контейнерами, можно добавить обработку
    }
  }

  addSkinSetting() {
    this.configData.skinSettings.push({ name: '', prompt: '', color: '', url: '' });
  }

  addBodySetting() {
    this.configData.bodySettings.push({ name: '', visibleName: '', prompt: '', image: '' });
  }

  confirmDelete(type: string, index: number) {
    if (confirm("Are you sure you want to delete this item?")) {
      if (type === 'skin') {
        this.configData.skinSettings.splice(index, 1);
      } else if (type === 'body') {
        this.configData.bodySettings.splice(index, 1);
      }
    }
  }

  saveChanges() {
    this.sDConfigService.updateSDConfig(this.configData).subscribe(
      (res) => {
        this.snackBar.open("Updated", "Close", {
          duration: 2000,
          panelClass: ["green-snackbar"],
        });
      },
      (error) => {
        console.error("Error updating config:", error);
      }
    );
  }
}
