import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService, StableDiffusionSettings } from "./api.service";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SDConfigService {
  private configDataSubject: BehaviorSubject<StableDiffusionSettings> = new BehaviorSubject<StableDiffusionSettings>(null);
  public configData$ = this.configDataSubject.asObservable();
  constructor(private apiService: ApiService) {}

  getSDConfig(): Observable<any> {
    return this.apiService.getSDConfig().pipe(
      tap(data => this.setConfigData(data))
    );
  }

  getSDConfigFromOpenapi(): Observable<any> {
    return this.apiService.getOpenApiConfig().pipe(
      map(res => res?.components?.schemas?.StableDiffusionSettings)
    );
  }

  setConfigData(data: StableDiffusionSettings): void {
    this.configDataSubject.next(data);
  }

  updateSDConfig(data: StableDiffusionSettings): Observable<any> {
    return this.apiService.saveSDConfig(data);
  }
}
