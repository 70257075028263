import { Component, OnInit } from "@angular/core";
import { AiArtResponse } from "src/app/services/api.service";
import { AiArtsService } from "src/app/services/ai-arts.service";

@Component({
  selector: "app-ai-arts",
  templateUrl: "./ai-arts.component.html",
  styleUrls: ["./ai-arts.component.scss"],
})
export class AiArtsComponent implements OnInit {
  aiArts: AiArtResponse[] = [];
  editingArtId: number | null = null;
  updatedArt: AiArtResponse | null = null;

  constructor(private aiArtsService: AiArtsService) {}

  ngOnInit() {
    this.aiArtsService.getAiArts().subscribe((arts) => {
      this.aiArts = arts;
    });
  }

  beginEdit(art: AiArtResponse) {
    this.editingArtId = art.id;
    this.updatedArt = { ...art };
  }

  cancelEdit() {
    this.editingArtId = null;
    this.updatedArt = null;
  }

  saveChanges() {
    if (this.updatedArt && this.editingArtId === -1) {
      // Создание нового арта
      this.aiArtsService.createAiArt(this.updatedArt).subscribe(() => {
        // Перезагрузка списка артов
        this.loadArts();
      });
    } else if (this.updatedArt) {
      // Обновление существующего арта
      this.aiArtsService
        .updateAiArt(this.editingArtId, this.updatedArt)
        .subscribe(() => {
          // Перезагрузка списка артов
          this.loadArts();
        });
    }

    this.cancelEdit();
  }

  uploadImage(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let base64Image = e.target.result as string;

        // Удаление префикса
        const base64ImageWithoutPrefix = base64Image.split(",")[1];

        if (this.updatedArt) {
          this.updatedArt.image = base64ImageWithoutPrefix;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  loadArts() {
    this.aiArtsService.getAiArts().subscribe((arts) => {
      this.aiArts = arts;
    });
  }

  addNewArt() {
    const newArt: AiArtResponse = {
      id: -1, // временный ID для нового арта
      name: "",
      gender: "preferNotToSay",
      prompt: "",
      negativePrompt: "",
      image: "",
      priority: 0,
    };
    this.aiArts.unshift(newArt);
    this.beginEdit(newArt);
  }
}
