import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService, LoginRequest, LoginResponse } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private apiService: ApiService) {
    this.checkAuthentication();
  }

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticated = this.isAuthenticatedSubject.asObservable();

  setToken(token: string): void {
    localStorage.setItem("token", token);
    this.isAuthenticatedSubject.next(true);
  }

  private _loginHandler(token: string) {
    localStorage.setItem("token", token);
    this.isAuthenticatedSubject.next(true);
  }

  login(data: LoginRequest): Observable<boolean> {
    return this.apiService.login(data).pipe(
      map((response: LoginResponse) => {
        this._loginHandler(response.token);
        return true;
      })
    );
  }

  logout(): void {
    localStorage.removeItem("token");
    this.isAuthenticatedSubject.next(false);
  }

  checkAuthentication(): void {
    const token = localStorage.getItem("token");
    this.isAuthenticatedSubject.next(!!token);
  }
}
