import { AuthenticationSettingsComponent } from './pages/authentication-settings/authentication-settings.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { StableDiffusionConfigComponent } from './pages/stable-diffusion-config/stable-diffusion-config.component';
import { UsersComponent } from './pages/users/users.component';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import { PhotoPromptsComponent } from './pages/photo-prompts/photo-prompts.component';
import { PhotoStylesComponent } from './pages/photo-styles/photo-styles.component';
import { AiArtsComponent } from './pages/ai-arts/ai-arts.component';
import { NonAuthGuard } from './guards/non-auth.guard';
import { TokenisationComponent } from './pages/tokenisation/tokenisation.component';
import { PhotoSizeComponent } from './pages/photo-size/photo-size.component';
import { UndresserConfigComponent } from './pages/undresser-config/undresser-config.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [NonAuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'stable-diffusion-config', component: StableDiffusionConfigComponent, canActivate: [AuthGuard] },
  { path: 'undresser-config', component: UndresserConfigComponent, canActivate: [AuthGuard] },
  {
    path: 'stable-diffusion-config-v2',
    loadChildren: () => import('./pages/stable-diffusion-config-v2/stable-diffusion-config-v2.module').then(m => m.StableDiffusionConfigV2Module),
    canActivate: [AuthGuard]
  },
  {
    path: 'training-config',
    loadChildren: () => import('./pages/training-config/training-config.module').then(m => m.TrainingConfigModule),
    canActivate: [AuthGuard]
  },
  { path: 'monitoring', component: MonitoringComponent, canActivate: [AuthGuard] },
  { path: 'photoPrompts', component: PhotoPromptsComponent, canActivate: [AuthGuard] },
  { path: 'photoStyles', component: PhotoStylesComponent, canActivate: [AuthGuard] },
  { path: 'aiArts', component: AiArtsComponent, canActivate: [AuthGuard] },
  { path: 'tokenisation', component: TokenisationComponent, canActivate: [AuthGuard] },
  { path: 'auth-settings', component: AuthenticationSettingsComponent, canActivate: [AuthGuard] },
  { path: 'photo-size', component: PhotoSizeComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/monitoring', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
