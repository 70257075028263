import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { UndressSettings } from 'src/app/interfaces/common.interface';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-undresser-config',
  templateUrl: './undresser-config.component.html',
  styleUrls: ['./undresser-config.component.scss']
})
export class UndresserConfigComponent implements OnInit {
  configData: UndressSettings;
  Object = Object;

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.apiService.getUndressSettings().pipe(take(1)).subscribe(
      (res: UndressSettings) => {
        this.configData = res;
      },
      (error) => {
        console.error('Error fetching undress settings', error);
      }
    );
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  addMask() {
    this.configData.mask.push('');
  }

  confirmDeleteMask(index: number) {
    if (confirm('Are you sure you want to delete this mask?')) {
      this.configData.mask.splice(index, 1);
    }
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  onMaskInputChange(index: number, value: string) {
    this.configData.mask[index] = value;
    if (value === '') {
      this.configData.mask[index] = '';
    }
  }

  onNumberInputChange(key: string, value: any) {
    if (value === null || value === undefined || value === '') {
      this.configData[key] = 0; // Используйте значение по умолчанию, например, 0
    } else {
      this.configData[key] = +value; // Преобразование в число
    }
  }

  saveChanges() {
    this.apiService.updateUndressSettings(this.configData).pipe(take(1)).subscribe(
      (res) => {
        // this.snackBar.open('Updated', 'Close', {
        //   duration: 2000,
        //   panelClass: ['green-snackbar'],
        // });
      },
      (error) => {
        console.error('Error updating undress settings:', error);
      }
    );
  }
}
