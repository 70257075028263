import { Component, OnInit } from '@angular/core';
import { ApiService, AuthenticationSettings } from 'src/app/services/api.service';

@Component({
  selector: 'app-authentication-settings',
  templateUrl: './authentication-settings.component.html',
  styleUrls: ['./authentication-settings.component.scss']
})
export class AuthenticationSettingsComponent implements OnInit {
  authSettings: AuthenticationSettings | null = null;

  constructor(private authService: ApiService) { }

  ngOnInit(): void {
    this.loadSettings();
  }

  loadSettings(): void {
    this.authService.getAuthenticationSettings().subscribe({
      next: (settings) => this.authSettings = settings,
      error: (error) => console.error('Error loading authentication settings:', error)
    });
  }

  updateSettings(): void {
    if (this.authSettings) {
      this.authService.updateAuthenticationSettings(this.authSettings).subscribe({
        next: () => console.log('Authentication settings updated successfully'),
        error: (error) => console.error('Error updating authentication settings:', error)
      });
    }
  }
}
