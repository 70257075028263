import { Injectable } from "@angular/core";
import {
  ApiService,
  PhotoStyleRequest,
  PhotoStyleResponse,
} from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PhotoStylesService {
  constructor(private apiService: ApiService) {}

  getPhotoStyles(): Observable<PhotoStyleResponse[]> {
    return this.apiService.getPhotoStyles();
  }

  updatePhotoStyle(
    id: number,
    data: PhotoStyleRequest
  ): Observable<PhotoStyleResponse> {
    return this.apiService.updatePhotoStyle(id, data);
  }

  createPhotoStyle(data: PhotoStyleRequest): Observable<PhotoStyleResponse> {
    return this.apiService.createPhotoStyle(data);
  }
}
