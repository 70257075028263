import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService, AiArtResponse, AiArtRequest } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AiArtsService {
  constructor(private apiService: ApiService) {}

  getAiArts(): Observable<AiArtResponse[]> {
    return this.apiService.getAiArts();
  }

  updateAiArt(id: number, data: any): Observable<AiArtResponse> {
    return this.apiService.updateAiArt(id, data);
  }

  createAiArt(data: AiArtRequest): Observable<AiArtResponse> {
    return this.apiService.createAiArt(data);
  }
}
